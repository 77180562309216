




























































































import { Component } from 'vue-property-decorator';
import NotesTodoToolbar from './NotesTodoToolbar.vue';

@Component({
  components: {
    NotesTodoToolbar
  }
})
export default class NotesTodoBottomToolbar extends NotesTodoToolbar {
}
